<template>
    <div class="main">
        <div class="menuContent">
            <div class="topTitles">
                <!-- <div class="fileManagement">内容管理</div> -->
                <div class="addFile" @click="addId">+新增身份</div>
            </div>
            <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableDatas" style="width: 100%; ">
                <el-table-column prop="url" align="center" label="头像">
                    <template slot-scope="scope">
                        <div>
                            <img :src="scope.row.url" alt="" class="head">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" align="center" label="名称"></el-table-column>
                <el-table-column prop="count" align="center" label="已发布内容数量"></el-table-column>
                <el-table-column prop="vipTime" label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="tableMenu">
                            <div class="edit" @click="editId(scope.row)">编辑</div>
                            <div class="delete" @click="deleteId(scope.row)">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog title="新增身份" :visible.sync="idVisible" width="30%">
            <div style="display: flex;align-items: center;margin: 16px 0;">
                <div style="width: 50px;text-align: right;">头像：</div>
                <img :src="url" alt="" class="head" v-show="url" @load="imgLoad">
                <input type="file" @change="uploadhead" accept='image/*' ref='uploadhead' style="color:#fff" v-if="!url" />
                <el-link type="primary" :underline="false" @click="url = ''" v-if="url">删除</el-link>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
                <div style="width: 50px;text-align: right;">名称：</div>
                <el-input v-model="name" placeholder="请输入名称" style="width: 200px;"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelIdMethod">取 消</el-button>
                <el-button type="primary" @click="idMethod">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getTagApi, operationTagApi, countTagApi } from '../../apis/index'
import uploadImg from '../../utils/uploadImg.js'
export default {
    data() {
        return {
            idVisible: false,
            tableDatas: [],
            url: '',
            name: '',
            id: '',
            moduleId: 1,
        }
    },
    computed: {

    },
    created() {

        this.getIdList()
    },
    methods: {

        async getCountList() {//获取发布数量
            try {
                const { data } = await countTagApi()
                if (data.code == 200) {
                    if(!data.data) return
                    let tableDatas = this.tableDatas
                    let countObj = data.data
                    if (tableDatas.length > 0) {
                        tableDatas.forEach(item => {
                            Object.keys(countObj).map(k => {
                                if (item.id == k) {
                                    item.count = countObj[k]
                                }
                            })
                        })
                        console.log(tableDatas)
                        this.tableDatas = tableDatas
                    }
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }

        },
        async getIdList() {//获取身份列表
            try {
                const { data } = await getTagApi()
                if (data.code == 200) {
                    this.tableDatas = data.data.map(item => {
                        item.count = 0
                        return item
                    })
                    this.getCountList()
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }

        },
        addId() {
            this.idVisible = true
            this.id = ''
            this.moduleId = 1,
                this.name = ''
            this.url = ''
        },
        cancelIdMethod() {
            this.idVisible = false
        },
        editId(row) {//编辑
            this.idVisible = true
            this.id = row.id
            this.moduleId = row.moduleId,
            this.name = row.name
            this.url = row.url
        },
        async publicMethod(params, method) {
            try {
                const { data } = await operationTagApi(params, method)
                if (data.code == 200) {
                    this.idVisible = false
                    this.getIdList()
                    this.$message.success(data.msg)
                } else {
                    this.$message.error(data.msg)
                }
            } catch (e) {
                console.log(e)
                this.$message.error('请求异常')
            }

        },
        idMethod() {//身份增加、修改
            if (this.id) {//编辑
                const params = {
                    name: this.name,
                    url: this.url,
                    id: this.id,
                    moduleId: this.moduleId
                }
                this.publicMethod(params, 'put')
            } else {//添加
                const params = {
                    name: this.name,
                    url: this.url,
                    moduleId: this.moduleId
                }
                this.publicMethod(params, 'post')
            }
        },
        deleteId(row) {
            const params = {
                name: row.name,
                url: row.url,
                id: row.id,
                moduleId: row.moduleId
            }
            let that = this
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.publicMethod(params, 'delete')
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        uploadhead() {//上传头像
            // this.$refs['uploadhead'].value = ''
            this.loading = this.$loading({
                lock: true,
                text: '数据加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            if (this.$refs['uploadhead'].files[0]) {
                let selectFileobj = this.$refs['uploadhead'].files[0]
                uploadImg(selectFileobj, (res) => {
                    this.url = res
                })
            }
        },
        imgLoad() {
            this.loading.close();
        }
    },
}
</script>

<style lang="less" scoped>
.edit {
    color: #006AEC;
    margin-right: 10px;
}

.delete {
    color: #B80000;
}

.head {
    width: 104px;
    height: 104px;
}

.main {
    margin: 31px 29px;
    padding: 42px;
    background: #fff;
    border-radius: 8px;
    box-sizing: border-box;
}

.menuContent {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
}

.topTitles {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
}

.fileManagement {
    font-size: 20px;
    color: #3D3D3D;
    font-weight: bold;
}

.addFile {
    width: 96px;
    height: 32px;
    background: #00A78E;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
}

.tableMenu {
    display: flex;
    justify-content: center;
    font-size: 14px;
}
</style>